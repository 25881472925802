.ui.selection.dropdown.proc_data-filter-dropdown {
  border-color: #e4e4e4;
  padding-bottom: 12px;
  padding-left: 14px;
  padding-right: 34px;
  padding-top: 12px;
  user-select: none;
}
.ui.selection.dropdown.proc_data-filter-dropdown:focus,
.ui.selection.dropdown.proc_data-filter-dropdown.active {
  border-color: #f6cc99;
}
.ui.selection.dropdown.proc_data-filter-dropdown > .text {
  color: #474747;
  font-size: 16px;
  line-height: 16px;
  margin-left: 30px;
}
.ui.selection.dropdown.proc_data-filter-dropdown > .text.default {
  color: #767676;
}
.ui.selection.dropdown.proc_data-filter-dropdown .proc_data-filter-dropdown__left-icon {
  font-size: 14px;
  left: 15px;
  opacity: 0.5;
  position: absolute;
  top: 13px;
}
.ui.selection.dropdown.proc_data-filter-dropdown .proc_data-filter-dropdown__right-icon {
  height: auto;
  margin: -0.78571429em;
  padding: 0.91666667em;
  position: absolute;
  right: 1em;
  top: 0.78571429em;
  width: auto;
  z-index: 3;
}
.ui.selection.dropdown.proc_data-filter-dropdown .visible.menu.transition .item {
  border-top: none;
  box-shadow: inset 0 1px 0 0 #e4e4e4;
  color: #232323;
}
.ui.selection.dropdown.proc_data-filter-dropdown .visible.menu.transition .item.selected {
  background-color: #f1f1f1;
}
.ui.selection.dropdown.proc_data-filter-dropdown .visible.menu.transition .item.selected.active {
  background-color: #f1f1f1;
}
.ui.selection.dropdown.proc_data-filter-dropdown .visible.menu.transition .item:hover {
  background-color: #fefaf5;
}
.ui.search.selection.dropdown.proc_data-filter-dropdown > input.search {
  padding-left: 44px;
}
.ui.search.selection.dropdown.proc_data-filter-dropdown > .text {
  margin-left: 29px;
}
