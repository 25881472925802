a:hover .proc_circle-icon,
button:hover .proc_circle-icon,
.ui.dropdown:hover .proc_circle-icon {
  background: #d48a2e;
}
button.proc_circle-icon {
  padding: 0;
  padding-top: 1px;
  height: 48px;
  width: 48px;
}
button.proc_circle-icon:hover {
  cursor: pointer;
  background: #d48a2e;
}
button.proc_circle-icon:focus {
  outline: none;
}
.proc_circle-icon {
  align-items: center;
  background: #ec9933;
  border-radius: 50%;
  border: double 3px #ffffff;
  display: flex;
  height: 42px;
  width: 42px;
  justify-content: center;
  position: relative;
  text-align: center;
}
.proc_circle-icon__text {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  user-select: none;
}
