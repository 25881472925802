.proc_circular-progress {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.proc_circular-progress .proc_circular-progress__display-value {
  color: #ec9933;
  display: inline-block;
  line-height: normal;
}
.proc_circular-progress svg.proc_circular-progress__circles {
  position: absolute;
  top: 0;
  right: 0;
  transform: rotateY(-180deg) rotateZ(-90deg);
}
.proc_circular-progress svg.proc_circular-progress__circles .proc_circular-progress__dynamic-circle {
  stroke-linecap: butt;
  stroke: #ec9933;
  fill: none;
}
.proc_circular-progress svg.proc_circular-progress__circles .proc_circular-progress__background-circle {
  stroke-linecap: butt;
  stroke: #e4e4e4;
  fill: none;
}
