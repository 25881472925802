.proc_app-sidebar {
  background: #ffffff;
  box-shadow: 1px 0 4px 0 #eaf5f9;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 63px);
  justify-content: space-between;
  overflow: hidden;
  position: fixed;
  transition: 500ms ease;
  user-select: none;
  width: 210px;
}
.proc_app-sidebar--collapsed {
  width: 63px;
}
.proc_app-sidebar__item {
  align-items: center;
  border-bottom: 1px solid rgba(118, 118, 118, 0.1);
  color: #767676;
  display: flex;
  font-size: 15px;
  height: 63px;
  text-decoration: none;
}
.proc_app-sidebar__item--active {
  color: #ec9933;
  box-shadow: inset 3px 0 #ec9933;
}
.proc_app-sidebar__item--active:hover {
  box-shadow: inset 3px 0 #ec9933, 0 1px 0 0 rgba(236, 153, 51, 0.15) !important;
}
.proc_app-sidebar__item-icon {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 63px;
}
.proc_app-sidebar__item-text {
  display: flex;
  margin-left: 63px;
  justify-content: center;
  opacity: 1;
  transition: 500ms ease;
  visibility: visible;
}
.proc_app-sidebar__item-text--collapsed {
  opacity: 0;
  visibility: hidden;
}
.proc_app-sidebar__item:hover {
  background: #fefaf5;
  box-shadow: 0 1px 0 0 rgba(236, 153, 51, 0.15);
  color: #ec9933;
  cursor: pointer;
}
.proc_app-sidebar__logos {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 48px 45px;
  overflow: hidden;
}
.proc_app-sidebar__logo {
  margin-bottom: 48px;
  max-width: 120px;
  opacity: 1;
  transition: 500ms ease;
  transform: scale(1);
  visibility: visible;
}
.proc_app-sidebar__logo:last-of-type {
  margin-bottom: 0;
}
.proc_app-sidebar__logo--collapsed {
  opacity: 0;
  transform: scale(0);
  visibility: hidden;
}
