.proc_orbital-loader {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  user-select: none;
  width: 100%;
}
.proc_orbital-loader__header {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding-bottom: 42px;
}
.proc_orbital-loader__header__logo {
  align-items: center;
  color: #ec9933;
  display: flex;
  height: 63px;
}
.proc_orbital-loader__header__logo__image {
  height: 63px;
}
.proc_orbital-loader__header__logo__text {
  font-size: 33px;
  font-weight: 300;
  line-height: 42px;
  margin: 0 0 0 21px;
}
.proc_orbital-loader__content {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}
.proc_orbital-loader__content__title {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 42px;
}
.proc_orbital-loader__content__title__action {
  line-height: 1.5;
}
.proc_orbital-loader__content__title__action--attention {
  color: #ec9933;
}
.proc_orbital-loader__content__title__action--busy {
  color: #3197c4;
}
.proc_orbital-loader__content__title__action--error {
  color: #db2828;
}
.proc_orbital-loader__content__title__action--success {
  color: #4dc86a;
}
.proc_orbital-loader__content__title__object {
  color: #232323;
  font-size: 19px;
  font-weight: bold;
  line-height: 1.17;
}
.proc_orbital-loader__content__info-text {
  color: #767676;
  line-height: 21px;
  max-width: 670px;
  text-align: center;
  margin-top: 21px;
}
