.ui.menu.proc_app-header {
  border-bottom: solid 1px #ffffff;
  border-image: linear-gradient(to left, #ec9933, #e4e4e4 25%) 1;
  height: 63px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
.ui.menu .proc_app-header__logo {
  max-width: 170px;
  transition: 500ms ease-in-out;
}
.ui.menu .proc_app-header__logo:hover {
  cursor: pointer;
  transform: scale(1.05);
}
.ui.menu .proc_app-header__menu-toggle {
  cursor: pointer;
  stroke: #767676;
  transition: 500ms ease-in-out;
}
.ui.menu .proc_app-header__menu-toggle--collapse {
  cursor: pointer;
  stroke: #767676;
  transform: rotateY(180deg);
  transition: 500ms ease-in-out;
}
.ui.menu .proc_app-header__menu-toggle:hover,
.ui.menu .proc_app-header__menu-toggle--collapse:hover {
  background: #fefaf5;
  stroke: #ec9933;
}
.ui.menu .proc_app-header__center-area {
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin: 0 14px 0 14px;
  width: 100%;
}
.ui.menu .proc_app-header__shop-icon {
  align-items: center;
  display: flex;
  margin-right: 40px;
}
.ui.menu .proc_app-header__user-account {
  align-items: center;
  display: flex;
  margin-right: 23px;
}
