.proc_steps {
  display: flex;
  white-space: nowrap;
}
.proc_steps .proc_steps__item {
  display: inline-block;
  flex: 1;
  font-size: 16px;
  justify-content: flex-start;
  margin-right: 14px;
  overflow: hidden;
  position: relative;
  user-select: none;
  vertical-align: top;
}
.proc_steps .proc_steps__item:hover .proc_steps__item__icon,
.proc_steps .proc_steps__item:hover .proc_steps__item__title,
.proc_steps .proc_steps__item:hover .proc_steps__item__description {
  color: #27799d;
}
.proc_steps .proc_steps__item .proc_steps__item__icon {
  display: inline-block;
  font-size: 24px;
  height: 28px;
  line-height: 32px;
  margin-top: 18px;
  margin-right: 9px;
  text-align: center;
  width: 28px;
}
.proc_steps .proc_steps__item .proc_steps__item__content {
  cursor: pointer;
  display: inline-block;
  margin-top: 21px;
  vertical-align: top;
}
.proc_steps .proc_steps__item .proc_steps__item__content .proc_steps__item__title {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  height: 21px;
  line-height: 21px;
  padding-right: 14px;
  position: relative;
}
.proc_steps .proc_steps__item .proc_steps__item__content .proc_steps__item__title::after {
  background-color: #e4e4e4;
  content: '';
  cursor: default;
  display: block;
  height: 3px;
  left: 100%;
  position: absolute;
  top: 9px;
  width: 9999px;
}
.proc_steps .proc_steps__item .proc_steps__item__content .proc_steps__item__description {
  font-size: 13px;
}
.proc_steps .proc_steps__item:last-child {
  flex: none;
  margin-right: 0;
}
.proc_steps .proc_steps__item:last-child .proc_steps__item__content .proc_steps__item__title {
  padding-right: 0;
}
.proc_steps .proc_steps__item:last-child .proc_steps__item__content .proc_steps__item__title::after {
  content: none;
}
.proc_steps .proc_steps__item--active {
  color: #3197c4;
}
.proc_steps .proc_steps__item--active .proc_steps__item__icon {
  color: #3197c4;
}
.proc_steps .proc_steps__item--active .proc_steps__item__description {
  color: #6a6a6a;
}
.proc_steps .proc_steps__item--disabled {
  color: rgba(173, 173, 173, 0.5);
}
.proc_steps .proc_steps__item--disabled .proc_steps__item__icon {
  color: rgba(173, 173, 173, 0.5);
}
.proc_steps .proc_steps__item--disabled .proc_steps__item__description {
  color: rgba(173, 173, 173, 0.5);
}
.proc_steps .proc_steps__item--error {
  color: #db2828;
}
.proc_steps .proc_steps__item--error .proc_steps__item__icon {
  color: #db2828;
}
.proc_steps .proc_steps__item--error .proc_steps__item__description {
  color: #db2828;
}
.proc_steps .proc_steps__item--success {
  color: #adadad;
}
.proc_steps .proc_steps__item--success .proc_steps__item__icon {
  color: #3197c4;
}
.proc_steps .proc_steps__item--success .proc_steps__item__description {
  color: #adadad;
}
.proc_steps .proc_steps__item--waiting {
  color: #767676;
}
.proc_steps .proc_steps__item--waiting .proc_steps__item__icon {
  color: #adadad;
}
.proc_steps .proc_steps__item--waiting .proc_steps__item__description {
  color: #767676;
}
.proc_steps .proc_steps__item--before-active .proc_steps__item__content .proc_steps__item__title::after {
  background-color: #3197c4;
}
.proc_steps .proc_steps__item--before-disabled .proc_steps__item__content .proc_steps__item__title::after {
  background-color: #e4e4e4;
}
.proc_steps .proc_steps__item--before-error .proc_steps__item__content .proc_steps__item__title::after {
  background-color: #db2828;
}
.proc_steps .proc_steps__item--before-success .proc_steps__item__content .proc_steps__item__title::after {
  background-color: #3197c4;
}
.proc_steps .proc_steps__item--before-waiting .proc_steps__item__content .proc_steps__item__title::after {
  background-color: #e4e4e4;
}
