.proc_app-footer {
  align-items: center;
  border-image: linear-gradient(to left, #ec9933, #e4e4e4 25%) 1;
  border-top: solid 1px #ffffff;
  display: flex;
  font-size: 12px;
  height: 42px;
  justify-content: space-between;
  padding: 0 28px 0 28px;
  color: #adadad;
}
.proc_app-footer__copyright-info a {
  color: #adadad;
  text-decoration: underline;
}
.proc_app-footer__copyright-info a:hover {
  color: #3197c4;
  text-decoration: underline;
}
.proc_app-footer__link {
  color: #232323;
  margin-right: 14px;
}
.proc_app-footer__link a {
  color: inherit;
}
.proc_app-footer__link--icon {
  margin-right: 4px;
}
.proc_app-footer__link:hover {
  color: #3197c4;
  cursor: pointer;
}
.proc_app-footer__link:last-of-type {
  margin-right: 0;
}
