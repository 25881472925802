.proc_orbital-loader {
  position: relative;
  padding-bottom: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.proc_orbital-loader.tiny .proc_spinner {
  width: 204px;
  height: 204px;
}
.proc_orbital-loader.tiny .proc_image {
  width: 210px;
  height: 210px;
}
.proc_orbital-loader.tiny .proc_image img {
  width: 198px;
  height: 198px;
}
.proc_orbital-loader.medium .proc_spinner {
  width: 309px;
  height: 309px;
}
.proc_orbital-loader.medium .proc_image {
  width: 315px;
  height: 315px;
}
.proc_orbital-loader.medium .proc_image img {
  width: 303px;
  height: 303px;
}
.proc_orbital-loader.huge .proc_spinner {
  width: 414px;
  height: 414px;
}
.proc_orbital-loader.huge .proc_image {
  width: 420px;
  height: 420px;
}
.proc_orbital-loader.huge .proc_image img {
  width: 408px;
  height: 408px;
}
.proc_orbital-loader .proc_image {
  border: 3px solid white;
  border-radius: 50%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
  position: relative;
}
.proc_orbital-loader .proc_image img:first-child {
  background-image: linear-gradient(#f7fcfe, #feffff);
}
.proc_orbital-loader .proc_image img {
  position: absolute;
  top: 3px;
  left: 3px;
  border: 3px solid white;
  border-radius: 50%;
}
.proc_orbital-loader .proc_spinner {
  background: transparent;
  border: 3px solid white;
  border-radius: 50%;
}
.proc_orbital-loader .proc_spinner.proc_attention {
  border-bottom: 3px solid #db2828;
  border-left: 3px solid #ec9933;
  border-right: 3px solid #ec9933;
  border-top: 3px solid #db2828;
  animation: spin 6s linear infinite;
}
.proc_orbital-loader .proc_spinner.proc_busy {
  border-top: 3px solid #ec9933;
  animation: spin 2s linear infinite;
}
.proc_orbital-loader .proc_spinner.proc_error {
  border: 3px solid #db2828;
}
.proc_orbital-loader .proc_spinner.proc_success {
  border: 3px solid #4dc86a;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.proc_orbital-loader .proc_action-buttons {
  position: absolute;
  bottom: 0;
}
.proc_orbital-loader .proc_action-buttons > .ui.basic.button {
  background: white !important;
}
.proc_orbital-loader .proc_action-buttons > .ui.basic.button:hover {
  background: #f7fcfe !important;
}
