.proc_view-header {
  border-bottom: solid 1px #e4e4e4;
  display: flex;
  height: 126px;
  padding: 21px 28px 21px 28px;
}
.proc_view-header--without-breadcrumbs {
  height: 84px;
}
.proc_view-header__left {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  min-width: 0;
}
.proc_view-header__left__title-wrapper {
  display: flex;
  align-items: center;
}
.proc_view-header__left__return-button {
  align-items: center;
  border-radius: 50%;
  border: 1px solid #848484;
  color: #848484;
  display: flex;
  height: 33px;
  justify-content: center;
  margin-right: 12px;
  user-select: none;
  width: 33px;
}
.proc_view-header__left__return-button__arrow {
  padding-top: 3px;
}
.proc_view-header__left__return-button:hover {
  background: #fefaf5;
  border-color: #ec9933;
  color: #ec9933;
  cursor: pointer;
}
.proc_view-header__left h1.proc_view-header__left__title {
  color: #232323;
  font-family: 'Lato';
  font-size: 33px;
  font-weight: lighter;
  height: 42px;
  line-height: 42px;
  margin-top: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.proc_view-header__actions {
  align-items: center;
  display: flex;
  height: 42px;
  white-space: nowrap;
}
.proc_view-header__actions__action--with-icon {
  margin-left: 15px;
}
.ui.basic.button {
  background: #ffffff !important;
  margin-right: 14px;
}
.ui.basic.button:last-of-type {
  margin-right: 0;
}
