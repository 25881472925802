.ui.breadcrumb.proc_breadcrumb .proc_breadcrumb__section--active.active {
  color: #848484;
  font-weight: normal;
  line-height: 1.5;
}
.ui.breadcrumb.proc_breadcrumb .proc_breadcrumb__divider {
  color: #848484 50%;
  font-family: 'Lato';
  font-size: 14px;
  line-height: 1.5;
}
.ui.breadcrumb.proc_breadcrumb .proc_breadcrumb__section {
  color: #3197c4;
}
.ui.breadcrumb.proc_breadcrumb .proc_breadcrumb__section:hover {
  color: #6fb6d6;
  cursor: pointer;
}
