.ui.secondary.pointing.menu.proc_tabbed-navigation {
  border-color: #f1f1f1;
}
.ui.secondary.pointing.menu.proc_tabbed-navigation .proc_tabbed-navigation__item {
  color: #474747;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 20px;
  padding-bottom: 9px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 11px;
  text-transform: uppercase;
  border-color: #f1f1f1;
}
.ui.secondary.pointing.menu.proc_tabbed-navigation .proc_tabbed-navigation__item:hover {
  color: #ec9933;
}
.ui.secondary.pointing.menu.proc_tabbed-navigation .proc_tabbed-navigation__item.active.item {
  border-color: #ec9933;
  color: #181818;
}
.ui.secondary.pointing.menu.proc_tabbed-navigation .proc_tabbed-navigation__item.active.item:hover {
  color: #ec9933;
}
