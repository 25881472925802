.proc_user-account {
  text-decoration: none;
  color: #232323;
  font-size: 14px;
  font-weight: bold;
  user-select: none;
}
.proc_user-account.ui.dropdown div:first-of-type {
  align-items: center;
  display: inline-flex;
}
.proc_user-account.ui.dropdown .menu {
  right: 0;
  left: auto;
}
.proc_user-account .proc_circle-icon {
  margin-right: 7px;
}
.proc_user-account .proc_circle-icon i.icon {
  margin: unset;
}
.proc_user-account .proc_circle-icon.proc_logged-out {
  background-color: #fdf5eb;
  border: 3px solid #fdf5eb;
  color: #232323;
}
div.proc_user-account,
a.proc_user-account {
  align-items: center;
  display: flex;
}
div.ui.dropdown.proc_user-account:hover,
a.proc_user-account:hover {
  color: #ec9933;
  cursor: pointer;
}
a.proc_user-account:hover .proc_logged-out {
  background-color: #f9e0c2;
  border: 3px solid #f9e0c2;
}
